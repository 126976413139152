<template>
    <div>
        <breadcrumb
            name="Test Drive"
            title="Schedule"
            subtitle="Edit"
            @onTitleClicked="goBack"
        />

        <div class="body">
            <div>
                <choose-model
                    @onModelChosen="setModel"
                    :selectedModelID="item.model.id"
                    v-if="model_id"
                />
                <vs-row>
                    <vs-col vs-lg="6" vs-md="12">
                        <label for="branches">Choose Showroom</label>
                        <select v-model="branch_id" id="branches">
                            <option value="0" disabled selected>
                                Choose Showroom
                            </option>
                            <option
                                :value="branch.id"
                                v-for="(branch, index) in branches"
                                :key="index"
                            >
                                {{ branch.name_en }}
                            </option>
                        </select>
                    </vs-col>
                    <vs-col vs-lg="6" vs-md="12">
                        <label for="switch">Show On Mobile?</label>
                        <vs-switch id="switch" v-model="mobile_active" />
                    </vs-col>
                </vs-row>
                <vs-divider />
                <div class="days">
                    <vs-checkbox
                        v-model="chosenDays"
                        :vs-value="day.value"
                        v-for="(day, index) in days"
                        :key="index"
                        >{{ day.day }}
                    </vs-checkbox>
                </div>
                <div>
                    <vs-row>
                        <vs-col vs-lg="6" vs-md="12">
                            <label>Starts At</label>
                            <vs-input v-model="start_at" class="input" />
                        </vs-col>
                        <vs-col vs-lg="6" vs-md="12">
                            <label>Ends At</label>
                            <vs-input v-model="end_at" class="input" />
                        </vs-col>
                    </vs-row>
                    <div class="optionBtns">
                        <vs-button
                            icon-pack="feather"
                            icon="icon-save"
                            :disabled="invalidForm"
                            @click="confirmSaveData"
                            >Save
                        </vs-button>
                        <vs-button
                            type="border"
                            class="ml-5 mr-10"
                            color="primary"
                            @click="goBack"
                            >Cancel</vs-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import ChooseModel from "../../../components/custom/choose-model";

export default {
    components: { ChooseModel, Breadcrumb },
    name: "add-settings",
    data() {
        return {
            id: this.$route.params.id,
            days: [
                { day: "Sunday", value: 1 },
                { day: "Monday", value: 2 },
                { day: "Tuesday", value: 3 },
                {
                    day: "Wednesday",
                    value: 4,
                },
                { day: "Thudsday", value: 5 },
                { day: "Friday", value: 6 },
                { day: "Saturday", value: 7 },
            ],
            chosenDays: [],
            branches: [],
            model_id: "",
            branch_id: "",
            start_at: "",
            end_at: "",
            item: {},
            mobile_active: null,
            modelCounter: 0,
            loaded: false,
            mobile_flag: false,
        };
    },
    methods: {
        getData() {
            this.$vs.loading();

            this.$httpAdmin
                .get(`test-drives/${this.id}`)
                .then((r) => {
                    console.log(r);
                    this.$vs.loading.close();
                    this.item = r.data.data;
                    console.log({ item: this.item });
                    this.model_id = this.item.model.id;
                    this.modelCounter++;
                    this.branch_id = this.item.branch.id;
                    this.chosenDays = this.item.days;
                    this.start_at = this.item.start_at;
                    this.end_at = this.item.end_at;
                    this.mobile_active = this.item.mobile_active;
                    this.loaded = true;

                    const timezone = Intl.DateTimeFormat().resolvedOptions()
                        .timeZone;

                    console.log({ timeZone: timezone });

                    console.log({ start_at: this.start_at });
                    console.log({ end_at: this.end_at });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't load Data!",
                        color: "danger",
                    });
                });
        },
        setModel(model_id) {
            this.model_id = model_id;
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to save these data!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm",
            });
        },
        goBack() {
            this.$router.push("/test-drive-settings");
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get(`/brands`)
                .then((res) => {
                    this.brands = res.data.data.brands;
                    this.$vs.loading.close();
                    console.log(res);
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        timeConvert(time) {
            time = time
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice(1);
                time[5] = +time[0] < 12 ? " AM" : " PM";
                time[0] = +time[0] % 12 || 12;
                if (time[0] < 10) {
                    time[0] = "0" + time[0];
                }
            }
            return time.join("");
        },
        pushItem(array) {
            array.push({
                start_at: "",
                end_at: "",
            });
        },
        deleteItem(array, index) {
            array.splice(index, 1);
        },
        saveData() {
            if (!/^\d{2}[:]\d{2}\s[AP][M]$/.test(this.start_at)) {
                this.$vs.notify({
                    title: "Error",
                    text: '"Start At" time format is incorrect!',
                    color: "danger",
                });
                return;
            }

            if (!/^\d{2}[:]\d{2}\s[AP][M]$/.test(this.end_at)) {
                this.$vs.notify({
                    title: "Error",
                    text: '"End At" time format is incorrect!',
                    color: "danger",
                });
                return;
            }

            let start_at = Date.parse("01/01/2020 " + this.start_at);
            let end_at = Date.parse("01/01/2020 " + this.end_at);

            console.log(start_at.toString().toLowerCase());
            console.log(end_at.toString().toLowerCase());

            if (
                start_at > end_at &&
                this.end_at.toLowerCase() !== "00:00" &&
                this.end_at.toLowerCase() !== "12:00 am" &&
                this.end_at.toLowerCase() !== "12:00am"
            ) {
                this.$vs.notify({
                    title: "Error",
                    text: '"Start At" time must be earlier than "End At" time!',
                    color: "danger",
                });
                return;
            }

            let payload = {
                model_id: this.model_id,
                branche_id: this.branch_id,
                days: this.chosenDays,
                start_at: this.timeConvert(this.start_at),
                end_at: this.timeConvert(this.end_at),
                mobile_active: this.mobile_active ? "1" : "0",
            };

            this.$vs.loading();
            this.$httpAdmin
                .patch(`/test-drives/${this.id}`, payload)
                .then((r) => {
                    console.log(r);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Data Updated Successfully",
                        color: "success",
                    });
                    this.goBack();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't Update Data!",
                        color: "danger",
                    });
                });
        },
        validateEmail(email) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        getBranches() {
            this.$vs.loading();
            this.$httpBranches
                .get(`/branches`)
                .then((res) => {
                    let total = res.data.data.total;

                    this.$httpBranches
                        .get(`/branches?per_page?${total}`)
                        .then((res) => {
                            let branches = res.data.data.data;

                            for (let i in branches) {
                                for (let x in branches[i].types) {
                                    if (branches[i].types[x] == 1) {
                                        this.branches.push(branches[i]);
                                    }
                                }
                            }

                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Something Error",
                                text: "Couldn't fetch data from the server",
                                color: "danger",
                            });
                        });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    watch: {
        brand_id(value) {
            for (let i in this.brands) {
                if (this.brands[i].id === value) {
                    this.brand_name = this.brands[i].name_en;
                    break;
                }
            }
        },
        // mobile_active(value) {
        //   if (this.loaded) {
        //     if (value != this.item.mobile_active || this.mobile_flag) {
        //       this.$vs.loading();
        //       this.mobile_flag = true;
        //       this.$httpAdmin.get(`test-drives/activation/${this.id}`)
        //         .then(r => {
        //           console.log(r);
        //           this.$vs.loading.close();
        //           if (value) {
        //             this.$vs.notify({
        //               title: "Success",
        //               text: "Test Drive is Activated Successfully!",
        //               color: "success"
        //             });
        //           } else {
        //             this.$vs.notify({
        //               title: "Success",
        //               text: "Test Drive is Deactivated Successfully!",
        //               color: "success"
        //             });
        //           }
        //         })
        //         .catch(e => {
        //           this.$vs.loading.close();
        //           console.log(e);
        //           this.$vs.notify({
        //             title: "Error",
        //             text: "Couldn't load Data!",
        //             color: "danger"
        //           });
        //         });
        //     }
        //   }
        // }
    },
    computed: {
        invalidForm() {
            if (
                this.chosenDays.length !== 0 &&
                this.model_id !== "0" &&
                this.branch_id !== "0" &&
                this.start_at &&
                this.end_at
            ) {
                return false;
            }
            return true;
        },
    },
    mounted() {
        this.getBrands();
        this.getBranches();
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.body {
    border-radius: 10px;
    padding: 20px;
}

.optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.days {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.input {
    width: 90%;
}

.emails {
    height: 175px;
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 90%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.body {
    background-color: #fff;
    padding-top: 20px;
}
</style>
